<template>
    <button class="btn btn-nano btn-clipboard" :class="custom_class"
            :style="custom_style"
            v-clipboard="data" title="Копировать текст"
            v-on:click="successCopyInfo($event)">
        <i class="fas fa-copy font-small-xs"></i>
    </button>
</template>

<script>
    import Vue from 'vue';
    import Clipboard from 'v-clipboard';

    Vue.use(Clipboard);

    export default {
        name: 'clipboard-block',

        props: {
            data: {
                type: [String, Number],
                required: true,
                default: '',
            },
            custom_class: {
                type: String,
                required: true,
                default: 'btn-default',
            },
            custom_style: {
                type: String,
                required: false,
            },
        },

        methods: {
            successCopyInfo(event) {
                let clipboardElement = event.currentTarget;
                if (clipboardElement) {
                    setTimeout(() => {
                        clipboardElement.classList.add('btn-success');
                        clipboardElement.classList.remove(this.custom_class);
                        clipboardElement.style.opacity = '0.25';
                        setTimeout(() => {
                            clipboardElement.classList.add(this.custom_class);
                            clipboardElement.classList.remove('btn-success');
                            clipboardElement.style.opacity = '1';
                        }, 420);
                    }, 180);
                } else {
                    showInfo("<i class='fas fa-copy'></i> " +
                        this.data.substr(0, 100) +
                        (this.data.length > 100 ? ' ...' : '')
                    );
                }
            },
        },
    }
</script>

<style scoped></style>