<template>
  <tr>
    <td>
      <strong>{{ partner.id }}</strong>
    </td>
    <td class="nowrap-column">
      {{ partner.name }}
      <br/>
      <span v-if="!partner.is_approved" class="label label-warning">
        Не одобрен
      </span>
      <span v-else class="label label-success">
        Одобрен
      </span>
    </td>
    <td>
      <a :href="'tel:+' + partner.phone">
        <strong>{{ partner.phone }}</strong>
      </a>
      <clipboard-block :data="partner.phone" custom_class="btn-default"/>
    </td>
    <td class="nowrap-column">
      <strong>
        {{ partner.client_name }}
        <br/>
      </strong>
      <a :href="'tel:+' + partner.client_phone">
        <strong>{{ partner.client_phone }}</strong>
      </a>
      <clipboard-block :data="partner.client_phone" custom_class="btn-default"/>
    </td>
    <td class="nowrap-column">
      <strong>
        {{ partner.clients_count }}
      </strong>
    </td>
    <td class="nowrap-column">
      {{ partner.updated_date }}
      <small v-if="partner.editor">
        <br/>
        Обновил <strong>{{ partner.editor.name }}</strong>
      </small>
    </td>
    <td class="nowrap-column">
      <router-link :to="{
                     name: 'partner.edit',
                     params: {
                       partnerId: partner.id
                     }
                   }"
                   class="btn btn-primary"
                   style="margin: 3px 2px 2px 3px"
                   title="Редактировать">
        <i class="fa fa-edit"></i>
      </router-link>

      <button class="btn btn-danger" style="margin: 3px 2px 2px 3px"
              title="Удалить" type="button"
              v-on:click="removePartner(partner.id)">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';
import ClipboardBlock from './../../components/blocks/clipboard-block.vue';

Vue.use(VuejsDialog);

export default {
  name: 'partner-item',

  components: {
    ClipboardBlock,
  },

  props: {
    partner: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    removePartner(partnerId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/partners/' + partnerId).then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
